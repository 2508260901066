import "./home.scss";
import Widget from "../../components/widget/Widget";
import Table from "../../components/table/Table";
import CreditCard from "../../components/creditCard/CreditCard";
import { useContext } from "react";
import UserContext from "../../context/user/userContext";
import { useEffect } from "react";
import WidgetCard from "../../components/widgetCard/WidgetCard";
import UserAuthContext from "../../context/userAuth/userAuthContext";

const Home = () => {
  const userContext = useContext(UserContext);
  const { getIp, getTransfers} = userContext;
  const userAuthContext = useContext(UserAuthContext);
  const { loadUser } = userAuthContext;


  
  useEffect(() => {
    loadUser();
    getTransfers();
    getIp();
    //eslint-disable-next-line
  }, []);






  return (
    <div className="client-home">
      <div className="home_Container">
        <div className="card-details" >
          <div className="widgets">
            <Widget type="balance" />
            <WidgetCard type={"DEPOSIT"} amount={'$17,450'} period={'Last month'} />
            <WidgetCard type={"WITHDRAW"} amount={'$4,350'} period={'Last month'} />
            <WidgetCard type={"TRANSFER"} amount={'$6,000'} period={'Last month'} />
          </div>
          <CreditCard />
        </div>
        <div className="listContainer_home">
          <div className="listTitle">Latest Transactions</div>
          <Table />
        </div>
      </div>
    </div>
  );
};

export default Home;
