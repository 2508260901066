import './widget.scss';

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

const WidgetCard = ({ type, amount, period }) => {
  let icon;
  switch (type) {
    case 'DEPOSIT':
      icon = (
        <AccountBalanceWalletOutlinedIcon
          className='icon'
          style={{
            backgroundColor: 'rgba(128, 0, 128, 0.2)',
            color: 'purple',
          }}
        />
      );
      break;
    case 'WITHDRAW':
      icon = (
        <MonetizationOnOutlinedIcon
          className='icon'
          style={{ backgroundColor: 'rgba(0, 128, 0, 0.2)', color: 'green' }}
        />
      );
      break;
    case 'TRANSFER':
      icon = (
        <PersonOutlinedIcon
          className='icon'
          style={{
            color: 'crimson',
            backgroundColor: 'rgba(255, 0, 0, 0.2)',
          }}
        />
      );
      break;
    default:
      icon = (
        <PersonOutlinedIcon
          className='icon'
          style={{
            color: 'crimson',
            backgroundColor: 'rgba(255, 0, 0, 0.2)',
          }}
        />
      );
  }

  return (
    <div className='my_widgetCard'>
      <div className='left_widgetCard'>
        <span className='title'>{type}</span>
        <span className="counter" style={{ fontSize: "1.5rem"}}>
         {amount}
        </span>
        <span className="link" style={{ marginTop: ".5rem" }}>
        {period}
        </span>
      </div>
      <div className='right_widgetCard'>{icon}</div>
    </div>
  );
};

export default WidgetCard;
